import SectionsHOC from "./sections-hoc";
import TopBanner from "./topbanner";

const HomePage = () => {
  return (
    <>
      <TopBanner />
      <SectionsHOC />
    </>
  );
};

export default HomePage;
