import { Row, Col } from "react-bootstrap";
import {
  BsFillEnvelopeOpenFill,
  BsFillFileEarmarkArrowDownFill,
  BsLinkedin,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { useAppDispatch } from "../store/hooks/typedhooks";
import { uiActions } from "../store/ui-slice";
import { StaticMessages } from "../utils/interfaces/interfaces";

type Props = {
  children?: React.ReactNode;
  onMouseEventHandler: (item: string, e: React.MouseEvent<HTMLElement>) => void;
};
const HireMe = (props: Props) => {
  const dispatch = useAppDispatch();
  /****** EVENT HANDLING ******/
  /* PASS ON TO PARENT HOC FOR LOGIC */
  const onMouseEventHandler = (
    item: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    props.onMouseEventHandler(item, e);
  };

  const onClickShowEmailModal = () => {
    dispatch(uiActions.setModalActive(true));
  };
  return (
    <section
      id="HireMeSection"
      className="flex-center"
      style={{ minHeight: "100vh" }}
    >
      <div id="hireMe" className="hireme pt-5 pb-5">
        <h2 className="color__white text__center font__h1">
          I'm looking for a job
        </h2>
        <h4 className="text-center color__white">Get in touch</h4>
        <Row className="pt-4">
          <Col xs={12} className="display__flex-center display__sm-block">
            <a
              className="btn_1 mr-3 color_white"
              href="./assets/NormanSilvestre_Resume.pdf"
              target="_blank"
              rel="noreferrer"
              onMouseLeave={onMouseEventHandler.bind(null, "")}
              onMouseEnter={onMouseEventHandler.bind(
                null,
                StaticMessages.RESUME
              )}
            >
              <div className="display__flex-center icon__wrapper">
                <span className="icon">
                  <BsFillFileEarmarkArrowDownFill title="Resume download link" />
                </span>
                <span className="ml-2">
                  Download my <span className="h4 mb-0 d-block">Resume</span>
                </span>
              </div>
            </a>
            <a
              className="mr-3 color_white"
              href="https://www.linkedin.com/in/ngsilvestre"
              target="_blank"
              rel="noreferrer"
              onMouseLeave={onMouseEventHandler.bind(null, "")}
              onMouseEnter={onMouseEventHandler.bind(
                null,
                StaticMessages.LINKEDIN
              )}
            >
              <div className="display__flex-center icon__wrapper">
                <span className="icon">
                  <BsLinkedin />
                </span>
                <span className="ml-2">
                  Check me on <span className="h4 mb-0 d-block">LinkedIn</span>
                </span>
              </div>
            </a>
            <button
              className="mr-3 color_white button__unstyled display__sm-block mh__sm-auto mb__sm-10"
              onMouseLeave={onMouseEventHandler.bind(null, "")}
              onMouseEnter={onMouseEventHandler.bind(
                null,
                StaticMessages.EMAIL
              )}
              onClick={onClickShowEmailModal}
            >
              <div className="display__flex-center icon__wrapper">
                <span className="icon">
                  <BsFillEnvelopeOpenFill title="Email Link" />
                </span>
                <span className="ml-2">
                  Send me an <span className="h4 mb-0 d-block">Email</span>
                </span>
              </div>
            </button>
            <a
              className="mr-3 color_white"
              href="tel:0420871015"
              target="_blank"
              rel="noreferrer"
              onMouseLeave={onMouseEventHandler.bind(null, "")}
              onMouseEnter={onMouseEventHandler.bind(null, StaticMessages.CALL)}
            >
              <div className="display__flex-center icon__wrapper">
                <span className="icon">
                  <BsTelephoneInboundFill title="Contact Information" />
                </span>
                <span className="ml-2">
                  0420871015
                  <span className="h4 mb-0 d-block">Call Me</span>
                </span>
              </div>
            </a>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HireMe;
