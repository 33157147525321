import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import useCheckInViewById from "../../components/hooks/useCheckInViewById";
import CSSTransitionWrapper from "../../components/hooks/useCssTransition";
import NotFound from "../../components/notfound";
import { AnimationTypes } from "../../components/hooks/useCssTransition";
import { BiCheckDouble } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";

type Props = {
  data: any;
};
const ExperienceList = (props: Props) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any) => {
    setIndex(selectedIndex);
  };
  let ExperienceJSX: JSX.Element[] | JSX.Element = (
    <NotFound text="No Experiences List Found" />
  );
  if (props.data.length > 0) {
    ExperienceJSX = props.data.map((item: any, index: number) => {
      return (
        <div className="timeline__wrapper" key={index}>
          <div className="card2 shadow__thin">
            <div className="card2__content">
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <img
                    src={`../assets/images/${item.image}`}
                    alt=""
                    className="display__table mh-auto d-md-block d-sm-block"
                    width={225}
                    height={107}
                  />
                  <h3 className="h6 mb-0 text__center">
                    <span className="h5 "> {item.company}</span>
                    <br />
                    <small>Company</small>
                  </h3>
                  <h4 className="h6 mb-0 text__center">
                    <span className="h5"> {item.jobposition}</span>
                    <br />
                    <small>Position</small>
                  </h4>
                  <h4 className="h6 text__center">
                    <span className="h5"> {item.tenureship}</span>
                    <br />
                    <small>Tenureship</small>
                  </h4>
                  <hr />
                  {item.achievements && (
                    <>
                      <h4 className="h6 color__primary">
                        Notable Achievements:
                      </h4>
                      <ul className="mb-0 styled">
                        {item.achievements.map((e: any, i: any) => {
                          return (
                            <li key={i}>
                              <BiCheckDouble /> {e}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <p className="font__sm">{item.description}</p>
                  {item.tasks && (
                    <>
                      <h5 className="h6 color__primary">Tasks:</h5>
                      <ul className="mb-0 styled">
                        {item.tasks.map((e: any, i: any) => {
                          return (
                            <li key={i}>
                              <BiCheckDouble /> {e}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </Col>
              </Row>

              <a href={item.workURL} className="button__flat">
                <span>
                  <BsArrowRight size={"2em"} />
                  {item.workURLText}
                </span>
              </a>
            </div>
          </div>
        </div>
      );
    });
  }
  const isExperienceInView = useCheckInViewById("works_heading");

  return (
    <section
      id="WorksSection"
      className="container-fluid pt-5 pb-5 z-3 bg-1 before"
    >
      <Container id="works_heading" style={{ minHeight: "20vh" }}>
        <Row className="pt-5 z-2 pt-5">
          <Col>
            <CSSTransitionWrapper
              in={isExperienceInView}
              delay={200}
              animation={AnimationTypes.FLIPINX}
            >
              <h2 className="color_filled text__center text__uppercase font__h1">
                Work Experience
              </h2>
            </CSSTransitionWrapper>
            <CSSTransitionWrapper
              in={isExperienceInView}
              delay={400}
              animation={AnimationTypes.FADEINUP}
            >
              <h4 className="color_filled text-center">
                Experiences That Made Me What I am Today
              </h4>
            </CSSTransitionWrapper>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="z-2">
          <div className="timeline__gutter">{ExperienceJSX}</div>
        </div>
      </Container>
    </section>
  );
};
export default ExperienceList;
