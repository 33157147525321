import { BiCheckDouble } from "react-icons/bi";

type Props = {
  data: any;
  // trainingID: string;
  // title: string;
  // description: string;
  // message: string;
  // status: string;
};
const TrainingListItemModal = (props: Props) => {
  //let badge = <></>;
  // switch (props.status) {
  //   case "Completed":
  //     badge = <FaAward size={30} />;
  //     break;
  //   case "Ongoing":
  //     badge = <BsPencil size={30} />;
  //     break;
  //   case "Scheduled":
  //     badge = <BsJournalCheck size={30} />;
  //     break;
  //   default:
  //     badge = <FaAward size={30} />;
  //     break;
  // }

  return (
    <>
      <h3 className="color__primary">{props.data.title}</h3>
      <p className="mb-0 mt-0">{props.data.description}</p>
      <p>
        Status: {props.data.status}{" "}
        {props.data.status === "Completed" && (
          <a
            href={props.data.linkToCertificate}
            className="color__primary"
            target="_blank"
            rel="noreferrer"
          >
            (View Certificate)
          </a>
        )}
      </p>

      <hr />
      <h4 className="h5">Course Outline</h4>
      <ul className="styled">
        {props.data.courseHighlights.map((e: string, i: number) => (
          <li key={i}>
            <BiCheckDouble />
            {e}
          </li>
        ))}
      </ul>
    </>
  );
};

export default TrainingListItemModal;
