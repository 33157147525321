import { useEffect, useState } from "react";
import { RootState } from "../store";
import { useAppSelector } from "../store/hooks/typedhooks";
import SideNavList from "./header-side-nav-list";
import HeaderTopNav from "./header-top-nav";
import HeaderTopSettings from "./header-top-settings";
let isInitial = true;
const Header = () => {
  const isMobileView = useAppSelector((state: RootState) => state.ui.isMobile);
  const [sideNavVisible, setsideNavVisible] = useState<boolean>(false);

  useEffect(() => {
    const refRects = () => {
      let curr = document.getElementById("SkillsSection");
      let currIsView = curr?.getBoundingClientRect().top;
      if (currIsView! <= 300) {
        setsideNavVisible(true);
      } else {
        setsideNavVisible(false);
      }
    };
    if (!isMobileView) {
      window.addEventListener("scroll", refRects);
    }

    if (isInitial) {
      isInitial = false;
      return;
    }

    return () => {};
  }, [isMobileView]);

  // const onMouseEventHandler = (
  //   item: string,
  //   e: React.MouseEvent<HTMLElement>
  // ) => {
  //   switch (e.type) {
  //     case MouseEventType.MOUSE_ENTER:
  //       dispatch(uiActions.setMessage(item));
  //       dispatch(uiActions.setCardBackdrop(true));
  //       break;
  //     case MouseEventType.MOUSE_LEAVE:
  //       dispatch(uiActions.setCardBackdrop(false));
  //       dispatch(uiActions.setMessage(""));
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      {sideNavVisible && !isMobileView && <SideNavList />}
      <HeaderTopNav />
      <HeaderTopSettings />
    </>
  );
};

export default Header;
