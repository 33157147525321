import React from "react";
import ReactDOM from "react-dom";

type BackdropProps = {
  onClose: () => void;
  // onClick?: (data: any) => void;
};

type ModalOverlayProps = {
  children?: React.ReactNode;
};

interface PortalProps extends BackdropProps, ModalOverlayProps {}

const Backdrop = (props: BackdropProps) => {
  return <div className="backdrop" onClick={props.onClose}></div>;
};
const ModalOverlay = (props: ModalOverlayProps) => {
  return (
    <div className="modal__container">
      <div className="modal__content">{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlays") as HTMLElement;
const Modal = (props: PortalProps) => {
  //const [modalIsShown, setModalIsShown] = useState<boolean>(true);
  // const overlay = document.querySelector("body") as HTMLElement;
  // modalIsShown && overlay!.classList.add("modal-open");
  // !modalIsShown && overlay!.classList.remove("modal-open");

  const onCloseHandler = () => {
    //setModalIsShown(false);
    props.onClose();
  };
  return (
    <>
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
      {ReactDOM.createPortal(
        <Backdrop onClose={onCloseHandler}></Backdrop>,
        portalElement
      )}
    </>
  );
};

export default Modal;
