// import { uiActions } from "../../store/ui-slice";
// import { useAppDispatch } from "../../store/hooks/typedhooks";
// import { useEffect, useRef, useState } from "react";
import { useRef } from "react";
import TopBannerForm from "./topbanner-form";

const TopBanner = () => {
  const aboveTheFoldREF = useRef<HTMLElement>(null);
  //const [aboveTheFoldView, setaboveTheFoldView] = useState(false);

  // useEffect(() => {
  //   setaboveTheFoldView(true);
  // }, []);

  //const dispatch = useAppDispatch();
  // const onMouseEventHandler = (
  //   item: string,
  //   e: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   switch (e.type) {
  //     case "mouseenter":
  //       setTimeout(() => {
  //         dispatch(uiActions.setMessage(item));
  //       }, 150);
  //       break;
  //     case "mouseleave":
  //       dispatch(uiActions.setMessage(""));
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      <section
        id="AboutMeSection"
        className="topBanner flex-center"
        ref={aboveTheFoldREF}
      >
        <div className="container z-2">
          <div className="row ">
            <div className="col-12 col-md-12 col-lg-12 mb-5 mb-md-0 z-2  ox-hidden">
              <TopBannerForm />
            </div>
          </div>
        </div>
      </section>
      <div className="fixed-overlay"></div>
    </>
  );
};

export default TopBanner;
