import { Col } from "react-bootstrap";
import type { RootState } from "../../store";
import { useAppSelector } from "../../store/hooks/typedhooks";
import { FaAward } from "react-icons/fa";
import { BsArrowRight, BsJournalCheck, BsPencil } from "react-icons/bs";
import CSSTransitionWrapper, {
  AnimationTypes,
} from "../../components/hooks/useCssTransition";
import useCheckInViewById from "../../components/hooks/useCheckInViewById";

type Props = {
  id: string;
  trainingID: string;
  title: string;
  delay?: number;
  description: string;
  message: string;
  status: string;
  onMouseEventHandler: (
    item: string,
    e: React.MouseEvent<HTMLDivElement>
  ) => void;
  children?: React.ReactNode;
  onClick: (id: string) => void;
};
const TrainingListItem = (props: Props) => {
  const isActive = useAppSelector(
    (state: RootState) => state.ui.isMessageActive
  );

  let badge = <></>;
  switch (props.status) {
    case "Completed":
      badge = <FaAward size={30} />;
      break;
    case "Ongoing":
      badge = <BsPencil size={30} />;
      break;
    case "Scheduled":
      badge = <BsJournalCheck size={30} />;
      break;
    default:
      badge = <FaAward size={30} />;
      break;
  }
  const propsPassedID = useCheckInViewById(props.id);
  console.log(props);
  console.log(propsPassedID);
  return (
    <>
      <Col sm={12} md={6} lg={6} className="mb-4 mb-md-5" id={props.id}>
        <CSSTransitionWrapper
          in={propsPassedID}
          delay={200 * (props.delay! + 1)}
          animation={AnimationTypes.FADEINUP}
        >
          <div className="card2">
            {/* <span className="ribbon__content"></span> */}
            <div className="card2__content">
              <span style={{ color: "#43cea2" }}>
                {badge} {"  "}
                {props.status}
              </span>

              <h3 className="text-center mt-3 color_2">{props.title}</h3>
              <p className="text-center color_1">{props.description}</p>
              <button
                className="button__flat"
                onClick={props.onClick.bind(null, props.trainingID)}
              >
                <span>
                  <BsArrowRight size={"2em"} />
                  See course outline
                </span>
              </button>
            </div>
            {/* <div
            className="overlay-mask overlay-mask__front"
            onMouseEnter={onMouseEventHandler.bind(null, props.message)}
            onMouseLeave={onMouseEventHandler.bind(null, props.message)}
          ></div> */}
          </div>
        </CSSTransitionWrapper>
      </Col>
    </>
  );
};

export default TrainingListItem;
