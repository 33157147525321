import { Container, Row, Col } from "react-bootstrap";

type Props = {
  error: string;
};
const FetchError = (props: Props) => {
  return (
    <Container
      fluid
      style={{
        backgroundColor: "#FFF",
        zIndex: 5,
        position: "relative",
      }}
    >
      <Row>
        <Col className="flex__center">
          <h3 className="mt-5 mb-5 text__center">{props.error}</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default FetchError;
