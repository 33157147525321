import { StaticMessages } from "../utils/interfaces/interfaces";
import NavListItem from "./header-side-nav-list-item";
const SideNavList = () => {
  return (
    <ul className="sideNav">
      <NavListItem
        title="Skills"
        to="SkillsSection"
        message={StaticMessages.SKILLS}
      />
      <NavListItem
        title="Work"
        to="WorksSection"
        message={StaticMessages.WORK}
      />
      <NavListItem
        title="Training"
        to="TrainingSection"
        message={StaticMessages.TRAINING}
      />
      <NavListItem
        title="Contact"
        to="HireMeSection"
        message={StaticMessages.CONTACT}
      />
    </ul>
  );
};

export default SideNavList;
