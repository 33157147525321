import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import useCheckInViewById from "../../components/hooks/useCheckInViewById";
import CSSTransitionWrapper from "../../components/hooks/useCssTransition";
import Modal from "../../components/modal";
import NotFound from "../../components/notfound";
import TransitionFadeInUpDown from "../../components/transitionfade-in-out";
import { useAppDispatch } from "../../store/hooks/typedhooks";
import { uiActions } from "../../store/ui-slice";
import { AnimationTypes } from "../../components/hooks/useCssTransition";
import TrainingListItem from "./training-list-item";
import TrainingListItemModal from "./training-list-item-modal";
type Props = {
  onMouseEventHandler: (item: string, e: React.MouseEvent<HTMLElement>) => void;
  data: any;
};
const TrainingList = (props: Props) => {
  const dispatch = useAppDispatch();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [dataInModal, setDataInModal] = useState<any>();
  const hideItemHandler = () => {
    dispatch(uiActions.setModalActive(false));
    setModalShow(false);
    document.querySelector("body")!.classList.remove("open");
  };

  /****** EVENT HANDLING ******/
  /* PASS ON TO PARENT HOC FOR LOGIC */
  const onMouseEventHandler = (
    item: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    props.onMouseEventHandler(item, e);
  };

  const showCourseModal = (id: string) => {
    const currentIndex = props.data.findIndex((item: any) => item.id === id);
    const currentData = props.data[currentIndex];
    setDataInModal(currentData);
    setModalShow(true);
    document.querySelector("body")!.classList.add("open");
    //dispatch(uiActions.setModalActive(true));
  };

  const isTrainingSectionInView = useCheckInViewById("training_heading");
  const isTrainingListInView = useCheckInViewById("training_list");
  /****** COMPONENT RENDERING *****/
  let TrainingJSX: JSX.Element[] | JSX.Element = (
    <NotFound text="No Trainings List Found" />
  );
  if (props.data.length > 0) {
    TrainingJSX = props.data.map((item: any, index: number) => {
      return (
        <TrainingListItem
          key={index}
          id={`training-${index}`}
          trainingID={item.id}
          delay={index}
          title={item.title}
          description={item.description}
          message={item.message}
          status={item.status}
          onMouseEventHandler={onMouseEventHandler}
          onClick={showCourseModal}
        />
      );
    });
  }

  return (
    <section
      id="TrainingSection"
      className="container-fluid pt-5 pb-5 z-3 bg-1 before"
    >
      <Container id="training_heading">
        <Row className="mb-5 z-2 pt-5">
          <Col xs={12} style={{ minHeight: "20vh" }}>
            <CSSTransitionWrapper
              in={isTrainingSectionInView}
              delay={200}
              animation={AnimationTypes.FLIPINX}
            >
              <h2 className="color_filled text__center text__uppercase font__h1">
                Training Path
              </h2>
            </CSSTransitionWrapper>
            <CSSTransitionWrapper
              in={isTrainingSectionInView}
              delay={400}
              animation={AnimationTypes.FADEINUP}
            >
              <h4 className="color_filled text__center">
                Keeping track of technology
              </h4>
            </CSSTransitionWrapper>
            <CSSTransitionWrapper
              in={isTrainingSectionInView}
              delay={600}
              animation={AnimationTypes.FADEINUP}
            >
              <p className="text__center">
                In a field that's constantly evolving, I'm always looking for
                ways to improve
              </p>
            </CSSTransitionWrapper>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row id="training_list">{TrainingJSX}</Row>
      </Container>
      <TransitionFadeInUpDown in={modalShow} type="fade">
        <Modal onClose={hideItemHandler}>
          <TrainingListItemModal data={dataInModal} />
        </Modal>
      </TransitionFadeInUpDown>
    </section>
  );
};

export default TrainingList;
