export const validImports = {
  reacthooks: [
    "usestate",
    "useeffect",
    "useref",
    "usecontext",
    "usecallback",
    "usememo",
    "useimperativehandle",
    "usereducer",
    "customhook",
    "reduxtypedusedselector",
    "reduxactioncreators",
    "reduxcreateslice-configurestore",
    "createslice",
    "actioncreators",
    "typeduseselectors",
    "reactrouter-dynamicrouting",
    "dynamicrouting",
    "eventhandler-form",
    "eventhandler-mouse",
    "fetchingdata",
    "authentication",
    "authorization",
    "formvalidation",
    "storage",
    "viewportrendering",
    "hoc",
    "component",
    "conditionalrender",
    "variant",
    "jest",
    "rtl",
    "testing-jest",
    "testing-rtl",
    "codebase-pagination",
    "codebase-debouncing",
    "codebase-authorization",
    "codebase-authentication",
    "codebase-fetching",
    "codebase-formvalidation",
    "codebase-pagination",
    "codebase-storage",
    "codebase-viewport-render",
    "css-modulebased",
    "css-dynamicjs",
    "css-styledcomponents",
    "css-theming-sass",
    "css-rtg",
    "codebase-createportal",
    "designpattern-hoc",
    "designpattern-variant",
    "designpattern-presentational",
    "designpattern-provider",
    "designpattern-conditional",
    "react-router",
    "nextjs",
  ],
};
